<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-4 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="user_type-input"
                    >{{ $t('projects::implementers.columns.user_type').ucFirst() }}</label
                    >
                    <select
                        id="user_type-input"
                        v-model="model.user_type"
                        :class="{'border-danger': states.user_type === false}"
                        class="form-select"
                        name="user_type"
                        @change="fetchUsers"
                    >
                        <option v-if="false" :value="model.user_type === undefined ? undefined : null">
                            {{ $t('projects::implementers.placeholders.user_type').ucFirst() }}
                        </option>
                        <option v-for="(modelType, index) in userModelTypes" :key="index" :value="modelType.value" @keydown.enter="userStore = modelType.store" @click="userStore = modelType.store">
                            {{ modelType.label }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-4 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="user_id-input"
                    >{{ $t('projects::implementers.columns.user_id').ucFirst() }}</label
                    >
                    <select
                        id="user_id-input"
                        v-model="model.user_id"
                        :class="{'border-danger': states.user_id === false}"
                        class="form-select"
                        name="user_id"
                    >
                        <option :value="model.user_id === undefined ? undefined : null">
                            {{ $t('projects::implementers.placeholders.user_id').ucFirst() }}
                        </option>
                        <option v-for="(user, index) in users" :key="index" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.is_active === false}"
                        class="form-label"
                        for="is-active-input"
                    >{{ $t('projects::priorities.columns.is_active').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="is-active-input"
                            v-model="model.is_active"
                            :class="{'border-danger': states.is_active === false}"
                            class="form-check-input"
                            name="is_active"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                        {{ errors.is_active }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "implementer-form",

    emits: ['saved'],

    props: {
        implementer: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'implementer/loading',
            'states': 'implementer/states',
            'errors': 'implementer/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        userModelTypes: function () {
            return [
                {
                    value: 'App\\Models\\User',
                    store: 'user',
                    label: this.$tc('users.user', 2)
                }
            ]
        },

        billables: function () {
            return this.$store.dispatch(`${this.model}/all`, true)
        },
    },

    data: function () {
        return {
            model: {},
            userStore: 'user',
            users: []
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`implementer/${method}`, this.model).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/implementers/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
        },

        fetchUsers: function () {
            this.$store.dispatch(`${this.userStore}/all`, true).then(response => {
                this.users = response
            })
        },
    },

    watch: {
        implementer: function (value) {
            this.setModel(value)
        },
    },

    created() {
        this.setModel(this.implementer)
        if (!this.model.id && this.userModelTypes.length) {
            this.model.user_type = this.userModelTypes[0].value
            this.userStore = this.userModelTypes[0].store
        }
        this.fetchUsers()
    }
}
</script>

<style scoped>

</style>
